import React from 'react';
import styled from '@emotion/styled';
import NumberFormat from 'react-number-format';
import ModalWrapper from '~common/molecules/ModalWrapper';
import EmailQuoteForm from './EmailQuoteForm';
import Icon from '~common/atoms/Icon';
import { toWordCase } from '../shared/utils';
import HorizontalRule from '../shared/components/HorizontalRule';
import { useThemeUI } from 'theme-ui';
import Loading from '~common/atoms/Loading';
import { H2, H3, P1, P2, P3 } from '~common/atoms/typography';
import { Box, Flex, Text } from 'rebass';
import css from '@emotion/css';
import currencyFilter from '~lib/filters/currencyFilter';
import usePromotions from '~lib/hooks/sanity/usePromotions';
import PromotionsBanner from '~common/molecules/PromotionsBanner';

const StyledContainer = styled.div`
  // needed due to ModalWrapper hard-coded styles
  margin: -12px -12px -32px;
  width: calc(100% + 24px);

  padding: 32px 24px;

  ${props => props.theme.mq.sm} {
    padding: 40px 64px;
  }
`;

const StyledWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: column nowrap;

  ${props => props.theme.mq.sm} {
    flex-flow: row nowrap;
  }

  > * {
    flex: 0 1 46%;
  }

  > form {
    order: 2;

    ${props => props.theme.mq.sm} {
      order: 1;
    }
  }

  > div {
    order: 1;

    ${props => props.theme.mq.sm} {
      order: 2;
    }
  }
`;

const StyledLoading = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInstructions = styled.p`
  font-size: ${props => props.theme.fontSizes[3]}px;
  line-height: 1.2;
  text-align: center;
  margin-top: 20px;
  width: 100%;

  ${props => props.theme.mq.sm} {
    margin-bottom: 24px;
    text-align: left;
    max-width: 46%;
  }
`;

const StyledButton = styled.button`
  ${props => props.theme.buttons.minimal};
  position: absolute;
  top: 40px;
  right: 24px;

  ${props => props.theme.mq.sm} {
    top: 50px;
    right: 64px;
  }

  > * {
    pointer-events: none;
  }
`;

const RightSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledCard = styled.div`
  background-color: ${props => props.theme.colors.lightNeutral50};
  border: solid 2px ${props => props.theme.colors.lightBlue};
  padding: 24px 20px;
  width: 100%;
  margin-bottom: 20px;

  ${props => props.theme.mq.sm} {
    margin-top: -80px;
  }
`;

const StyledType = styled.p`
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes[1]}px;
  line-height: 16px;
  color: ${props => props.theme.colors.neutral500};
  margin-bottom: 4px;
`;

const StyledCoverTitle = styled.div`
  font-size: ${props => props.theme.fontSizes[3]}px;
  font-weight: 500;
  color: ${props => props.theme.colors.primary};
  margin-right: 16px;
  margin-bottom: 4px;

  strong {
    font-weight: bold;
  }
`;

const StyledPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledFrequency = styled(P3)`
  margin-top: 4.5px;
  color: ${props => props.theme.colors.neutral500};
`;

const StyledExcess = styled.p`
  margin-top: 4px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;

  span {
    color: ${props => props.theme.colors.primary};
  }
`;

const StyledSelection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
`;

const YouthDiscount = styled.div`
  display: flex;
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes[0]}px;
  line-height: 1.2;
  margin-top: 12px;

  svg {
    margin-right: 8px;
    flex-shrink: 0;
  }
`;

const EmailQuoteModal = ({
  form,
  isYouth,
  quote,
  prices,
  productType,
  selections,
  setToggleModal,
  toggleModal,
  confirmExit,
}) => {
  const { theme } = useThemeUI();
  const { data: promotions } = usePromotions({
    variables: {
      input: {
        lens: 'quickQuotePage',
        productType,
      },
    },
  });

  const showPromotionBanner = Boolean(
    promotions?.eligible && promotions?.content?.emailQuoteMessage
  );

  const closeButton = (
    <StyledButton
      onClick={() => {
        setToggleModal(false);
      }}
    >
      <Icon name="closeX" fill={theme.colors.primary} />
    </StyledButton>
  );

  return (
    <ModalWrapper
      toggleModal={toggleModal}
      setToggleModal={setToggleModal}
      width="800px"
      closeButton={closeButton}
    >
      <StyledContainer>
        <H2 variant="medium" color="primary">
          {confirmExit ? (
            <>
              <H2
                color="primary"
                variant="semiBold"
                css={css`
                  display: inline-block;
                `}
              >
                Save
              </H2>{' '}
              your quote before you go
            </>
          ) : (
            <>
              <H2
                color="primary"
                vatiant="semiBold"
                css={css`
                  display: inline-block;
                `}
              >
                Email me
              </H2>{' '}
              this quote
            </>
          )}
        </H2>
        {quote.loading ? (
          <StyledLoading>
            <Loading size="large" />
          </StyledLoading>
        ) : (
          <>
            <StyledInstructions>
              Enter your details below to save your quote via email so you can
              easily continue where you left off.
            </StyledInstructions>
            <StyledWrapper>
              <EmailQuoteForm selections={selections} form={form} />
              <RightSide>
                <StyledCard>
                  <Text fontSize={5} color="primary" mb="20px" fontWeight>
                    Your quote
                  </Text>
                  <StyledType>Hospital</StyledType>
                  {productType === 'hospital' || productType === 'both' ? (
                    <StyledSelection>
                      <Box>
                        <Box
                          css={css`
                            display: grid;
                          `}
                        >
                          <P1 variant="semiBold" color="primary" mr="5px">
                            {selections?.hospital?.product?.title}{' '}
                          </P1>
                          <P1 variant="medium" color="primary">
                            {selections?.hospital?.product?.coverage}
                          </P1>
                        </Box>

                        <StyledExcess>
                          with{' '}
                          {selections?.hospital?.variant?.excess ? (
                            <span>${selections.hospital.variant.excess}</span>
                          ) : (
                            'no'
                          )}{' '}
                          excess
                        </StyledExcess>
                      </Box>

                      <StyledPrice>
                        <P2 variant="medium" color="neutral900">
                          <NumberFormat
                            decimalScale={2}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            prefix={'$'}
                            thousandSeparator={true}
                            value={prices?.hospital?.net}
                          />
                        </P2>
                        <StyledFrequency variant="medium">
                          {toWordCase(form.paymentFrequency)}
                        </StyledFrequency>
                      </StyledPrice>
                    </StyledSelection>
                  ) : (
                    <StyledSelection>
                      <StyledCoverTitle>No Hospital Selected</StyledCoverTitle>
                    </StyledSelection>
                  )}

                  <HorizontalRule
                    color={theme.colors.neutral50}
                    mt={18}
                    mb={18}
                  />

                  <StyledType>Extras</StyledType>
                  {productType === 'extras' || productType === 'both' ? (
                    <StyledSelection>
                      <Box
                        css={css`
                          display: grid;
                        `}
                      >
                        <P1 mr="5px" variant="semiBold" color="primary">
                          {selections?.extras?.product?.title}{' '}
                        </P1>
                        <P1 variant="medium" color="primary">
                          {selections?.extras?.product?.coverage}
                        </P1>
                      </Box>

                      <StyledPrice>
                        <P2 variant="medium" color="neutral900">
                          <NumberFormat
                            decimalScale={2}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            prefix={'$'}
                            thousandSeparator={true}
                            value={prices?.extras?.net}
                          />
                        </P2>

                        <StyledFrequency variant="medium">
                          {toWordCase(form.paymentFrequency)}
                        </StyledFrequency>
                      </StyledPrice>
                    </StyledSelection>
                  ) : (
                    <StyledSelection>
                      <StyledCoverTitle>No Extras Selected</StyledCoverTitle>
                    </StyledSelection>
                  )}

                  <HorizontalRule
                    color={theme.colors.neutral50}
                    mt={18}
                    mb={18}
                  />

                  <StyledType>Total Premium</StyledType>
                  <StyledSelection>
                    <div>
                      <Flex alignItems="flex-end">
                        <H3 variant="semiBold" color="primary" mb={0} mr="5px">
                          {currencyFilter(prices?.total)}
                        </H3>
                        <P3 variant="medium" color="primary" lineHeight={1.5}>
                          {toWordCase(form.paymentFrequency)}
                        </P3>
                      </Flex>

                      {isYouth ? (
                        <YouthDiscount>
                          <Icon
                            name="discount"
                            width="16px"
                            height="16px"
                            fill={theme.colors.primary}
                            style={{ marginRight: 8 }}
                          />
                          You're entitled to a youth discount of up to 10% of
                          your hospital premium! This discount is not reflected
                          in the quote shown above but will be applied once we
                          receive your application.
                        </YouthDiscount>
                      ) : (
                        <YouthDiscount>
                          This quote does not include any applicable youth
                          discount or lifetime health cover loading and may
                          differ from your final premium.
                        </YouthDiscount>
                      )}
                    </div>
                  </StyledSelection>
                </StyledCard>
                {showPromotionBanner && (
                  <PromotionsBanner
                    asRichText={true}
                    content={promotions.content.emailQuoteMessage}
                    mt={3}
                  />
                )}
              </RightSide>
            </StyledWrapper>
          </>
        )}
      </StyledContainer>
    </ModalWrapper>
  );
};

export default EmailQuoteModal;
